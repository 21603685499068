








import { Component, Vue } from "vue-property-decorator";

import EntityCrud from "../../entity/EntityCrud.vue";
import blogPostSubcategoryAdminModel from "@/api/blog_post_subcategory.admin.model";

@Component({
	components: { EntityCrud },
})
export default class BlogPostCategories extends Vue {
	model: any = blogPostSubcategoryAdminModel;

	title = "Subcategorias de publicações";
	tableColumns = ["name"];
	filter = {};

	formColumns = ["name"];
	defaultEntity = {};

	reloadSubcategoriesOptions() {
		blogPostSubcategoryAdminModel.loadManyToOneOptions(true);
	}
}
